import React from "react"
import Shows from "../components/Shows"
import "../styles/styles.scss"
import Div100vh from "react-div-100vh"
import Helmet from "react-helmet"
import OG from "../images/og.jpg"
import { useEffect, useState } from "react"
export default function Home() {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => setLoading(true), 700)
  }, [])
  return (
    <>
      <Helmet
        title={"Yung Lean — Stardust"}
        meta={[
          {
            name: "apple-mobile-web-app-capable",
            content: "yes",
          },
          {
            name: "viewport",
            content:
              "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
          },
          {
            name: `description`,
            content: "Stardust — available now on all streaming platforms",
          },
          {
            property: `og:title`,
            content: "Yung Lean — Stardust",
          },
          {
            property: `og:description`,
            content: "Stardust — available now on all streaming platforms",
          },
          {
            property: `og:image`,
            content: `${OG}`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:image:src`,
            content: `https://yunglean.com${OG}`,
          },
          {
            name: `twitter:creator`,
            content: "Yung lean",
          },
          {
            name: `twitter:title`,
            content: "Yung Lean — Stardust",
          },
          {
            name: `twitter:description`,
            content: "Stardust — available now on all streaming platforms",
          },
        ]}
      ></Helmet>
      <Div100vh>
        <div className="container">
          <div className="links">
            <a
              className="listen"
              href="https://lnk.to/yl-stardust"
              target="_blank"
            >
              LISTEN NOW
            </a>
            <a className="watch" href="https://bit.ly/bliss-mv" target="_blank">
              WATCH ‘BLISS’
            </a>
            <a className="" href="/tour" target="_blank">
              TOUR TICKETS
            </a>
            <a
              className="merch"
              href="https://yungleangear.com"
              target="_blank"
            >
              MERCHANDISE
            </a>
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            background: "#fff",
            top: 0,
            left: 0,
            zIndex: 9999,
            opacity: loading ? 0 : 1,
            transition: "transition: opacity 10s ease-in-out",
          }}
          className="fade"
        ></div>
      </Div100vh>
    </>
  )
}
